import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/fr/contact/">Contact</a>
                    </li>
                    <li>
                      <a href="/fr/lexique/">Lexique</a>
                    </li>
                    <li className="last">
                      <a href="/fr/faq-questions-diarrhee/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li id="uilist-hor nav-top nav-lang_nl-BE">
                      
                    </li>
                    
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="6155e821b60ca5ec077f3e7a35de8164b6bf974d"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/fr/chercher"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <span className="logo">
                  <a href="/fr/">Imodium®</a>
                </span>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="first" id="nav-sub-4">
                        <a href="/fr/comment-agit-imodium/">
                          Comment agit
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li className="parent-active" id="nav-sub-5">
                        <a href="/fr/medicaments-imodium/">
                          Médicaments <br />
                          IMODIUM®
                        </a>
                      </li>
                      <li id="nav-sub-6">
                        <a href="/fr/la-diarrhee/">
                          Diarrhée – causes
                          <br />
                          et traitement
                        </a>
                      </li>
                      <li id="nav-sub-8">
                        <a href="/fr/diarrhee-en-voyage/">
                          Diarrhée
                          <br />
                          en voyage
                        </a>
                      </li>
                      <li id="nav-sub-9">
                        <a href="/fr/diarrhee-chez-les-enfants/">
                          Diarrhée chez
                          <br />
                          les enfants
                        </a>
                      </li>
                      <li className="last" id="nav-sub-10">
                        <a href="/fr/materiel-dinformation-diarrhee/">
                          Matériel
                          <br />
                          d'information
                        </a>
                      </li>
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="first overview">
                <a href="/fr/medicaments-imodium/"> Médicaments IMODIUM® </a>
              </li>
              <li>
                <a href="/fr/medicaments-imodium/imodium-instant/">
                  IMODIUM® Instant
                </a>
              </li>
              <li>
                <a href="/fr/medicaments-imodium/imodium-duo/">IMODIUM® Duo</a>
              </li>
              <li>
                <a href="/fr/medicaments-imodium/imodium-capsules/">
                  IMODIUM® Capsules
                </a>
              </li>
              
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Comment agit IMODIUM®?</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/widget.jpeg"
                    width="170"
                    height="102"
                    alt="Comment agit IMODIUM®?"
                  />
                </div>
                <p>
                  Cette vidéo montre comment Imodium<sup>®</sup>&nbsp;aide à
                  restaurer l‘équilibre naturel de vos intestins.
                </p>
                <a href="/fr/comment-agit-imodium/">Plus d'info</a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-52">
            <h2>Quel IMODIUM® est approprié pour vous</h2>
            <h3>
              {" "}
              Quel IMODIUM<sup>®</sup> choisir pour le traitement symptomatique
              de la diarrhée?
            </h3>
            <div className="enquete">
              <span className="arrow">&nbsp;</span>
              <div className="flexslider questions">
                <ul className="slides">
                  <li className="question">
                    <p className="question-nmbr">
                      <strong>Question 1/3:</strong>
                    </p>
                    <p>
                      <i>
                        L'un des symptômes suivants accompagne t-il la diarrhée?
                      </i>
                    </p>
                    <form method="post" action className="custom">
                      <p className="error">
                        Merci à répondre à la question avant de continuer.
                      </p>
                      <label htmlFor="radio1">
                        <input
                          name="check01"
                          type="radio"
                          id="radio1"
                          defaultValue="1"
                          className="question1"
                          style={{
                            display: "none"
                          }}
                        />
                        <span className="custom radio" /> Ballonnements
                      </label>
                      <label htmlFor="radio2">
                        <input
                          name="check01"
                          type="radio"
                          id="radio2"
                          defaultValue="2"
                          className="question1"
                          style={{
                            display: "none"
                          }}
                        />
                        <span className="custom radio" /> Crampes
                      </label>
                      <label htmlFor="radio3">
                        <input
                          name="check01"
                          type="radio"
                          id="radio3"
                          defaultValue="3"
                          className="question1"
                          style={{
                            display: "none"
                          }}
                        />
                        <span className="custom radio" /> Flatulence
                      </label>
                      <label htmlFor="radio4">
                        <input
                          name="check01"
                          type="radio"
                          id="radio4"
                          defaultValue="4"
                          className="question1"
                          style={{
                            display: "none"
                          }}
                        />
                        <span className="custom radio" /> Aucun des précédents
                      </label>
                      <div className="cf">
                        <a
                          href="#"
                          className="radius button right next-question"
                        >
                          Question suivante
                        </a>
                      </div>
                    </form>
                  </li>
                  <li className="question">
                    <p className="question-nmbr">
                      <strong>Question 2/3:</strong>
                    </p>
                    <p>
                      <i>
                        Êtes-vous à la recherche d'un remède agissant rapidement
                        contre la diarrhée ?
                      </i>
                    </p>
                    <form method="post" action className="custom">
                      <p className="error">
                        Merci à répondre à la question avant de continuer.
                      </p>
                      <label htmlFor="radio7">
                        <input
                          name="check3"
                          type="radio"
                          id="radio7"
                          defaultValue="1"
                          className="question3"
                          style={{
                            display: "none"
                          }}
                        />
                        <span className="custom radio" /> Oui
                      </label>
                      <label htmlFor="radio8">
                        <input
                          name="check3"
                          type="radio"
                          id="radio8"
                          defaultValue="2"
                          className="question3"
                          style={{
                            display: "none"
                          }}
                        />
                        <span className="custom radio" /> Non
                      </label>
                      <div className="cf">
                        <a href="#" className="left previous-question">
                          Retour
                        </a>
                        <a
                          href="#"
                          className="radius button right next-question"
                        >
                          Question suivante
                        </a>
                      </div>
                    </form>
                  </li>
                  <li className="question">
                    <p className="question-nmbr">
                      <strong>Question 3/3:</strong>
                    </p>
                    <p>
                      <i>
                        Éprouvez-vous des difficultés à avaler des comprimés ?
                      </i>
                    </p>
                    <form method="post" action className="custom">
                      <p className="error">
                        Merci à répondre à la question avant de continuer.
                      </p>
                      <label htmlFor="radio9">
                        <input
                          name="check4"
                          type="radio"
                          id="radio9"
                          defaultValue="1"
                          className="question4"
                          style={{
                            display: "none"
                          }}
                        />
                        <span className="custom radio" /> Oui
                      </label>
                      <label htmlFor="radio10">
                        <input
                          name="check4"
                          type="radio"
                          id="radio10"
                          defaultValue="2"
                          className="question4"
                          style={{
                            display: "none"
                          }}
                        />
                        <span className="custom radio" /> Non
                      </label>
                      <div className="cf">
                        <a href="#" className="left previous-question">
                          Retour
                        </a>
                        <a
                          href="#"
                          className="radius button right next-question"
                        >
                          Répondre
                        </a>
                      </div>
                    </form>
                  </li>
                  <li className="question">
                    <div className="results">
                      <div className="result result-lingual">
                        <div className="headline">
                          <p className="question-nmbr">
                            <strong>Réponse</strong>
                          </p>
                          <h3>
                            IMODIUM<sup>®</sup> Instant
                          </h3>
                          <p>
                            IMODIUM<sup>®</sup> instant comprimés
                            orodispersibles existent en conditionnement de 20 ou
                            60 comprimés orodispersibles.&nbsp;Le médicament est
                            disponible en pharmacie sans prescription.
                          </p>
                        </div>
                        <div className="media media-flipped">
                          <div className="img">
                            <img
                              alt="IMODIUM®  Instant (loperamide) handige hulp bij behandeling diarree en reizigersdiarree"
                              src="/assets/files/products/images/Imodium_Instant_2mg_20_tabs_3D_Frontal_FR.png"
                              style={{
                                width: "244px",
                                height: "135px"
                              }}
                            />
                          </div>
                          <div className="bd">
                            <ul>
                              <li>Fond sur la langue en quelques secondes</li>
                              <li>Facile à prendre sans eau</li>
                            </ul>
                            <a
                              className="button radius"
                              href="/fr/medicaments-imodium/imodium-instant/"
                            >
                              Vers le produit
                            </a>
                            <br />
                            <a className="next-question" href="#">
                              Répéter le test
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="result result-duo">
                        <div className="headline">
                          <p className="question-nmbr">
                            <b>Réponse</b>
                          </p>
                          <h3>
                            IMODIUM<sup>®</sup> Duo
                          </h3>
                          <p>
                            IMODIUM<sup>®</sup> Duo existe en conditionnement de
                            18 comprimés.&nbsp;Le médicament est disponible en
                            pharmacie sans prescription.
                          </p>
                        </div>
                        <div className="media media-flipped">
                          <div className="img">
                            <img
                              alt="IMODIUM®  Duo (loperamide + simeticon) bij behandeling diarree met pijnlijke krampen"
                              src="/assets/files/products/images/ImodiumDuo_18capl_3D_Frontal_FR.PNG"
                              style={{
                                width: "244px",
                                height: "187px"
                              }}
                            />
                          </div>
                          <div className="bd">
                            <ul>
                              <li>
                                Agit très rapidement en cas de diarrhée
                                accompagnée de crampes.
                              </li>
                              <li>Comprimés faciles à avaler</li>
                            </ul>
                            <a
                              className="button radius"
                              href="/fr/medicaments-imodium/imodium-duo/"
                            >
                              Vers le produit
                            </a>
                            <br />
                            <a className="next-question" href="#">
                              Répéter le test
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="result result-kapseln">
                        <div className="headline">
                          <p className="question-nmbr">
                            <b>Réponse</b>
                          </p>
                          <h3>
                            IMODIUM<sup>®</sup> Capsules
                          </h3>
                          <p>
                            IMODIUM<sup>®</sup> capsules existe en
                            conditionnement de 20, 60 ou 200 capsules. Le
                            médicament est disponible en pharmacie sans
                            prescription.
                          </p>
                        </div>
                        <div className="media media-flipped">
                          <div className="img">
                            <img
                              alt="IMODIUM®  Capsules (loperamide) bij behandeling diarree en reizigersdiarree"
                              src="/assets/files/products/images/Imodium_2mg_20_caps_3D_Frontal_FR.png"
                              style={{
                                width: "244px",
                                height: "182px"
                              }}
                            />
                          </div>
                          <div className="bd">
                            <ul>
                              <li>Le classique en cas de diarrhée</li>
                              <li>Disponible depuis plus de 30 ans déjà</li>
                              <li>Capsules faciles à avaler</li>
                            </ul>
                            <a
                              className="button radius"
                              href="/fr/medicaments-imodium/imodium-capsules/"
                            >
                              Vers le produit
                            </a>
                            <br />
                            <a className="next-question" href="#">
                              Répéter le test
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="row preview">
                <div className="four columns prod-small">
                  <div className="img">
                    <img
                      src="/assets/files/products/images/Imodium_Instant_2mg_20_tabs_3D_Frontal_FR.png"
                      width="126"
                    />
                  </div>
                  <a href="/fr/medicaments-imodium/imodium-instant/">
                    IMODIUM® Instant
                  </a>
                </div>
                <div className="four columns prod-small">
                  <div className="img">
                    <img
                      src="/assets/files/products/images/ImodiumDuo_18capl_3D_Frontal_FR.PNG"
                      width="90"
                    />
                  </div>
                  <a href="/fr/medicaments-imodium/imodium-duo/">
                    IMODIUM® Duo
                  </a>
                </div>
                <div className="four columns prod-small">
                  <div className="img">
                    <img
                      src="/assets/files/products/images/Imodium_2mg_20_caps_3D_Frontal_FR.png"
                      width="94"
                    />
                  </div>
                  <a href="/fr/medicaments-imodium/imodium-capsules/">
                    IMODIUM®
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/imodium-2018-fr.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="fr" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
